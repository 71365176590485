import store from "store2";
import { docCookies } from "./cookies";
import { getMainDomain } from "./url";

// token

const TOKEN_KEY = "sub_token";

export const storeGetToken = (): string | null => docCookies.getItem(document.cookie, TOKEN_KEY);

export const storeSaveToken = (token: string) => {
  docCookies.setItem(TOKEN_KEY, token, 15 * 24 * 3600, "/", getMainDomain(window.location.hostname) || "subscan.io");
};

export const storeClearToken = () => {
  docCookies.removeItem(document.cookie, TOKEN_KEY, "/", getMainDomain(window.location.hostname) || "subscan.io");
};

// preference

const PREFERENCE_KEY = "timezone_type";

export const storeSavePreference = (type: string) => {
  docCookies.setItem(
    PREFERENCE_KEY,
    type,
    15 * 24 * 3600,
    "/",
    getMainDomain(window.location.hostname) || "subscan.io"
  );
};

export const storeClearPreference = () => {
  docCookies.removeItem(document.cookie, PREFERENCE_KEY);
};

// organization

const ORGANIZATION_KEY = "organization:id";

export const storeGetOrganizationId = (): string | null => store(ORGANIZATION_KEY);

export const storeSaveOrganizationId = (id: number) => {
  store(ORGANIZATION_KEY, id);
};

export const storeClearOrganizationId = () => {
  store.remove(ORGANIZATION_KEY);
};

// notification visible

const NOTIFICATION_VISIBLE_KEY = "notification:hidden";

export const storeGetNotificationVisible = (): boolean | null => store(NOTIFICATION_VISIBLE_KEY);

export const storeSaveNotificationVisible = (hidden: boolean) => {
  store(NOTIFICATION_VISIBLE_KEY, hidden);
};

// wallet access

const WALLET_ACCESS_KEY = "wallet:access";

export const storeGetWalletAccess = (): boolean | null => store(WALLET_ACCESS_KEY);

export const storeSaveWalletAccess = (accessed: boolean) => {
  store(WALLET_ACCESS_KEY, accessed);
};
