import { useEffect, useMemo, useRef, useState } from "react";
import { encodeAddress } from "@polkadot/util-crypto";
import { useApp } from "src/hooks";
import { CSSTransition } from "react-transition-group";
import { sameWidth } from "src/config";
import { usePopper } from "react-popper";
import { shortenString } from "src/utils";
import clsx from "clsx";
import avatarDefault from "src/assets/images/avatar_default.svg";
import iconLogout from "src/assets/images/icon_logout.svg";

export const User = ({ disableBorder }: { disableBorder?: boolean }) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const { userInfo, saveToken, saveUserInfo } = useApp();

  const userName = useMemo(() => {
    const username = userInfo?.username || "";
    const email = userInfo?.email || "";
    let address = "";
    if (userInfo?.public_key) {
      try {
        address = shortenString(encodeAddress(userInfo.public_key));
      } catch (err) {
        console.error(err);
      }
    }

    if (username && address) {
      return (
        <div>
          <span className="text-sm font-semibold">{shortenString(username, 10)}</span>
          <span className="text-sm hidden md:inline-block">({shortenString(address)})</span>
        </div>
      );
    }

    if (username && email) {
      return (
        <div>
          <span className="text-sm font-semibold">{shortenString(username, 10)}</span>
          <span className="text-sm hidden md:inline-block">({shortenString(email)})</span>
        </div>
      );
    }

    return <span className="text-sm font-semibold">{shortenString(username || email, 5)}</span>;
  }, [userInfo?.email, userInfo?.public_key, userInfo?.username]);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      sameWidth,
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (e.target && referenceElement && !referenceElement.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  }, [referenceElement]);

  return userInfo ? (
    <>
      <button
        ref={setReferenceElement}
        className={clsx(
          "flex items-center gap-middle h-9 px-middle rounded hover:outline-none focus-visible:outline-none transition-transform hover:opacity-80 active:opacity-60",
          { "bg-white": disableBorder },
          { "bg-b4": !disableBorder }
        )}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className="overflow-hidden rounded-full flex-shrink-0">
          <img className="w-6 h-6 rounded-full" src={userInfo?.avatar || avatarDefault} alt="avatar" />
        </div>
        {userName}
        {userInfo.unpaid_order_count ? <div className="bg-error rounded-full w-[6px] h-[6px]" /> : null}
        <img src={iconLogout} alt="logout" className="w-4 h-4" />
      </button>
      <CSSTransition in={open} timeout={300} unmountOnExit nodeRef={nodeRef} classNames="fade-dropdown">
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="z-10">
          <div
            ref={nodeRef}
            style={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)" }}
            className={`border border-b3 rounded bg-white flex flex-col`}
          >
            <ButtonItem
              text="Logout"
              onClick={() => {
                setOpen(false);
                saveToken(null);
                saveUserInfo(null);
              }}
            />
          </div>
        </div>
      </CSSTransition>
    </>
  ) : null;
};

const ButtonItem = ({
  text,
  haveUnpaidOrder,
  onClick,
}: {
  text: string;
  haveUnpaidOrder?: boolean;
  onClick: () => void;
}) => (
  <button
    className="text-normal text-sm w-full py-2 rounded transition active:scale-95 hover:bg-gray-800/10 flex items-center justify-center gap-middle"
    onClick={onClick}
  >
    <div className={`bg-error rounded-full w-[6px] h-[6px] ${haveUnpaidOrder ? "visible" : "invisible"}`} />
    <span className="text-normal text-sm">{text}</span>
  </button>
);
