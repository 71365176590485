import axios from "axios";
import config from "src/config/config";
import { storeGetToken, storeGetOrganizationId, storeClearToken } from "./store";

const instance = axios.create({
  baseURL: config.baseUrl,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.code === 2005) {
      storeClearToken();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  const token = storeGetToken();
  const organization = storeGetOrganizationId();

  config.headers = {
    ...config.headers,
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...(organization ? { "OPEN-PLATFORM-ORGANIZATION": organization } : {}),
  };

  return config;
});

export const request = instance;
