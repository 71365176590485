import { lazy } from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

import AppLayout from "./layout/AppLayout";
import AppHomePageLayout from "./layout/AppHomePageLayout";
import AppLoginSignupLayout from "./layout/AppLoginSignupLayout";
import App404Page from "./pages/app-404-page";
import { ErrorCatcher } from "./components/ErrorCatcher";
import { PrivateRoute } from "./components/PrivateRoute";

// app
const AppSettingPage = lazy(() => import("src/pages/app-setting-page"));
const AppLoginWithWeb3Page = lazy(() => import("src/pages/app-login-with-web3-page"));
const AppLoginWithEmailPage = lazy(() => import("src/pages/app-login-with-email-page"));
const AppSignupWithWeb3Page = lazy(() => import("src/pages/app-signup-with-web3-page"));
const AppSignupWithEmailPage = lazy(() => import("src/pages/app-signup-with-email-page"));
const AppNeedVerifyEmailPage = lazy(() => import("src/pages/app-need-verify-email-page"));
const AppHomePage = lazy(() => import("src/pages/app-home-page"));
const AppPricingPage = lazy(() => import("src/pages/app-pricing-page"));
const AccountPreferencePage = lazy(() => import("src/pages/account-preference-page"));
const AccountProfilePage = lazy(() => import("src/pages/account-profile-page"));
const AppOverviewPage = lazy(() => import("src/pages/app-overview-page"));

// api service
const ApiServicePage = lazy(() => import("src/pages/api-service-page"));
const ApiServiceBillingHistoryPage = lazy(() => import("src/pages/api-service-billing-history-page"));
const ApiServiceKeyStatusPage = lazy(() => import("src/pages/api-service-key-status-page"));
const ApiServicePlansPage = lazy(() => import("src/pages/api-service-plans-page"));
const ApiServicePaymentPage = lazy(() => import("src/pages/api-service-payment-page"));
const ApiServiceCryptoPaymentPage = lazy(() => import("src/pages/api-service-crypto-payment-page"));

// widgets
const WidgetsPage = lazy(() => import("src/pages/widgets-page"));
const WidgetShare = lazy(() => import("src/pages/widget-share"));
const WidgetsPublishHistoryPage = lazy(() => import("src/pages/widgets-publish-history-page"));
const WidgetsChangesUpdatePage = lazy(() => import("src/pages/widgets-changes-update-page"));
const WidgetsPublishPage = lazy(() => import("src/pages/widgets-publish-page"));
const WidgetsCustomizedNewPagePage = lazy(() => import("src/pages/widgets-customized-new-page-page"));
const WidgetsPublishPageEditPage = lazy(() => import("src/pages/widgets-publish-edit-page"));

// from email
const AppVerifyEmailPage = lazy(() => import("src/pages/from-email/app-verify-email-page"));
const AppInvalidTokenPage = lazy(() => import("src/pages/from-email/app-invalid-token-page"));
const AppForgotPasswordPage = lazy(() => import("src/pages/from-email/app-forgot-password-page"));
const AppDeleteAccountPage = lazy(() => import("src/pages/from-email/app-delete-account-page"));
const AppLinkEmailPasswordPage = lazy(() => import("src/pages/from-email/app-link-email-password-page"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    errorElement: <ErrorCatcher />,
    children: [
      // home page
      {
        index: true,
        element: (
          <AppHomePageLayout>
            <AppHomePage />
          </AppHomePageLayout>
        ),
      },
      {
        path: "pricing",
        element: (
          <AppHomePageLayout>
            <AppPricingPage />
          </AppHomePageLayout>
        ),
      },
      {
        path: "overview",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: (
              <PrivateRoute>
                <AppOverviewPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // api service
      {
        path: "api_service",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: (
              <PrivateRoute needVerifyEmail>
                <ApiServicePage />
              </PrivateRoute>
            ),
          },
          {
            path: "billing_history",
            element: (
              <PrivateRoute needVerifyEmail>
                <ApiServiceBillingHistoryPage />
              </PrivateRoute>
            ),
          },
          {
            path: "subscan_status",
            element: (
              <PrivateRoute needVerifyEmail>
                <ApiServiceKeyStatusPage />
              </PrivateRoute>
            ),
          },
          {
            path: "plans",
            element: (
              <PrivateRoute needVerifyEmail>
                <ApiServicePlansPage />
              </PrivateRoute>
            ),
          },
          {
            path: "payment",
            element: (
              <PrivateRoute needVerifyEmail>
                <Outlet />
              </PrivateRoute>
            ),
            children: [
              {
                index: true,
                element: <ApiServicePaymentPage />,
              },
              {
                path: "crypto_payment",
                element: <ApiServiceCryptoPaymentPage />,
              },
            ],
          },
        ],
      },

      // widget
      {
        path: "widget/:id",
        element: <WidgetShare />,
      },

      // widgets
      {
        path: "widgets",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: (
              <PrivateRoute needVerifyEmail>
                <WidgetsPage />
              </PrivateRoute>
            ),
          },
          {
            path: "history",
            element: (
              <PrivateRoute needVerifyEmail>
                <WidgetsPublishHistoryPage />
              </PrivateRoute>
            ),
          },
          {
            path: "update_widget_changes",
            element: (
              <PrivateRoute needAdminRole>
                <WidgetsChangesUpdatePage />
              </PrivateRoute>
            ),
          },
          {
            path: "publish",
            element: (
              <PrivateRoute needAdminRole>
                <Outlet />
              </PrivateRoute>
            ),
            children: [
              {
                index: true,
                element: <WidgetsPublishPage />,
              },
              {
                path: "customized_new_page",
                element: <WidgetsCustomizedNewPagePage />,
              },
              {
                path: "edit",
                element: <WidgetsPublishPageEditPage />,
              },
            ],
          },
        ],
      },

      {
        path: "setting",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: (
              <PrivateRoute>
                <AppSettingPage />
              </PrivateRoute>
            ),
          },
        ],
      },

      {
        path: "preference",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: (
              <PrivateRoute>
                <AccountPreferencePage />
              </PrivateRoute>
            ),
          },
        ],
      },

      {
        path: "profile",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: (
              <PrivateRoute>
                <AccountProfilePage />
              </PrivateRoute>
            ),
          },
        ],
      },

      {
        path: "login",
        element: <AppLoginSignupLayout type="login" />,
        children: [
          {
            index: true,
            element: <AppLoginWithEmailPage />,
          },
          {
            path: "email",
            element: <AppLoginWithEmailPage />,
          },
          {
            path: "web3",
            element: <AppLoginWithWeb3Page />,
          },
        ],
      },
      {
        path: "signup",
        element: <AppLoginSignupLayout type="signup" />,
        children: [
          {
            index: true,
            element: <AppSignupWithEmailPage />,
          },
          {
            path: "email",
            element: <AppSignupWithEmailPage />,
          },
          {
            path: "web3",
            element: <AppSignupWithWeb3Page />,
          },
        ],
      },
      {
        path: "need_verify_email",
        element: (
          <PrivateRoute>
            <AppNeedVerifyEmailPage />
          </PrivateRoute>
        ),
      },
      {
        path: "verify_email/:token",
        element: (
          <PrivateRoute>
            <AppVerifyEmailPage />
          </PrivateRoute>
        ),
      },
      {
        path: "forgot_password/:token",
        element: <AppForgotPasswordPage />,
      },
      {
        path: "delete_account/:token",
        element: <AppDeleteAccountPage />,
      },
      {
        path: "link_email_password/:token",
        element: <AppLinkEmailPasswordPage />,
      },
      {
        path: "invalid_token",
        element: <AppInvalidTokenPage />,
      },
    ],
  },
  {
    path: "*",
    element: <App404Page />,
  },
]);
