import { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { from, Subscription } from "rxjs";
import {
  storeGetToken,
  storeSaveToken,
  storeClearToken,
  storeSaveOrganizationId,
  storeClearOrganizationId,
  storeSavePreference,
} from "src/utils";
import type { UserInfoResponseData, PlanResponseData } from "src/types";
import { apiGetMyPlans, apiGetUserInfo } from "src/api";

interface AppCtx {
  token: string | null;
  userInfo: UserInfoResponseData | null;
  myPlans: PlanResponseData[];
  updatingMyPlans: boolean;
  updatingUserInfo: boolean;
  anchorScrollCount: number;
  anchorRescroll: () => void;
  saveToken: (token: string | null) => void;
  saveUserInfo: (userInfo: UserInfoResponseData | null) => void;
  updateMyPlans: () => Subscription;
  updateUserInfo: () => Subscription;
}

const defaultValue: Partial<AppCtx> = {
  token: null,
  userInfo: null,
  myPlans: [],
  updatingMyPlans: false,
  updatingUserInfo: false,
  anchorScrollCount: 1,
  anchorRescroll: () => undefined,
  saveToken: () => undefined,
  saveUserInfo: () => undefined,
};

export const AppContext = createContext(defaultValue as AppCtx);

export const AppProvider = ({ children }: PropsWithChildren<{}>) => {
  const [token, setToken] = useState<string | null>(storeGetToken());
  const [myPlans, setMyPlans] = useState<PlanResponseData[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfoResponseData | null>(null);

  const [anchorScrollCount, setAnchorScrollCount] = useState(1);
  const anchorRescroll = useCallback(() => setAnchorScrollCount((prev) => prev + 1), []);

  const [updatingMyPlans, setUpdatingMyPlans] = useState(false);
  const [updatingUserInfo, setUpdatingUserInfo] = useState(false);

  const saveToken = useCallback((token: string | null) => {
    if (token) {
      storeSaveToken(token);
    } else {
      storeClearToken();
    }
    setToken(token);
  }, []);

  const saveUserInfo = useCallback((userInfo: UserInfoResponseData | null) => {
    if (userInfo?.organizations?.length) {
      storeSaveOrganizationId(userInfo.organizations[0].id);
    } else {
      storeClearOrganizationId();
    }
    storeSavePreference(userInfo?.time_zone || "UTC");
    setUserInfo(userInfo);
  }, []);

  const updateUserInfo = useCallback(() => {
    setUpdatingUserInfo(true);

    return from(apiGetUserInfo()).subscribe({
      next: ({ status, data }) => {
        setUpdatingUserInfo(false);
        if (status === 200 && data.code === 0 && data.data) {
          saveUserInfo(data.data);
        } else {
          saveToken(null);
          saveUserInfo(null);
        }
      },
      error: (err) => {
        setUpdatingUserInfo(false);
        console.error("error:", err);
      },
    });
  }, [saveToken, saveUserInfo]);

  useEffect(() => {
    let sub$$: Subscription;
    if (storeGetToken()) {
      sub$$ = updateUserInfo();
    }
    return () => {
      if (sub$$) {
        sub$$.unsubscribe();
      }
    };
  }, [updateUserInfo]);

  const updateMyPlans = useCallback(() => {
    setUpdatingMyPlans(true);

    return from(apiGetMyPlans()).subscribe({
      next: ({ status, data }) => {
        if (status === 200 && data.code === 0) {
          setMyPlans(data.data || []);
        } else {
          setMyPlans([]);
        }
        setUpdatingMyPlans(false);
      },
      error: (err) => {
        console.error(err);
        setUpdatingMyPlans(false);
      },
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        token,
        myPlans,
        userInfo,
        updatingMyPlans,
        updatingUserInfo,
        anchorScrollCount,
        saveToken,
        saveUserInfo,
        updateUserInfo,
        updateMyPlans,
        anchorRescroll,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
