import { request } from "src/utils";

export const apiGetWidgets = (params: { payload?: boolean }) =>
  request({
    url: "/open/v2/widgets",
    method: "GET",
    params: {
      payload: params.payload ?? false,
    },
  });

export const apiGetWidget = (params: { id: string }) =>
  request({
    url: "/open/v2/widget",
    method: "GET",
    params: {
      visitor_id: params.id,
    },
  });

export const apiCreateWidget = (params: { name: string; payload: string; networks: number[] }) =>
  request({
    url: "/open/v2/widget",
    method: "POST",
    data: {
      name: params.name,
      payload: params.payload,
      networks: params.networks,
    },
  });

export const apiUpdateWidget = (params: { id: number; name: string; payload: string; networks: number[] }) =>
  request({
    url: "/open/v2/widget",
    method: "PUT",
    data: {
      ...params,
    },
  });

export const apiDeleteWidgets = (params: { ids: number[] }) =>
  request({
    url: "/open/v2/widget",
    method: "DELETE",
    data: params.ids,
  });

export const apiGetAppliedWidgets = () =>
  request({
    url: "/open/v2/release/widget/history",
    method: "GET",
  });

export const apiCreateNewPage = (params: { url: string; name: string; network_id: number }) =>
  request({
    url: "/open/v2/network/page",
    method: "POST",
    data: {
      url: params.url,
      name: params.url,
      network_id: params.network_id,
    },
  });

export const apiCreatePreview = (params: { page_id: number; widgets: { id: number; is_fixed: boolean }[] }) =>
  request({
    url: "/open/v2/release/preview",
    method: "POST",
    data: {
      page_id: params.page_id,
      widgets: params.widgets,
    },
  });

export const apiUploadResource = (params: { data: FormData }) =>
  request({
    url: "/open/v2/resource",
    method: "POST",
    data: params.data,
  });

export const apiUploadAvatar = (params: { data: FormData }) =>
  request({
    url: "/open/v2/resource?type=avatar",
    method: "POST",
    data: params.data,
  });

export const apiGetNetworks = (params: { ids?: number[] }) =>
  request({
    url: "/open/v2/networks",
    method: "GET",
    params: {
      ids: params.ids?.join(","),
    },
  });

export const apiGetNetworksInfo = () =>
  request({
    url: "/open/v2/networks/info",
    method: "GET",
  });

export const apiCreateRelease = (params: {
  is_draft: boolean;
  network_id: number;
  pages: {
    id: number;
    message?: string | null;
    attachments?: {
      url: string;
      type: "image";
    }[];
    widgets: {
      id: number;
      is_fixed: boolean;
    }[];
  }[];
}) =>
  request({
    url: "/open/v2/release",
    method: "POST",
    data: params,
  });

export const apiUpdateRelease = (params: {
  id: number;
  is_draft?: boolean;
  network_id?: number;
  pages?: {
    id: number;
    message?: string | null;
    comment?: string;
    attachments?: {
      url: string;
      type: "image";
    }[];
    widgets?: {
      id: number;
      is_fixed?: boolean;
    }[];
  }[];
}) =>
  request({
    url: "/open/v2/release",
    method: "PUT",
    data: params,
  });

export const apiGetChangedWidgets = () =>
  request({
    url: "/open/v2/widgets/change",
    method: "GET",
  });

export const apiPublishChangedWidgets = (params: { ids: number[] }) =>
  request({
    url: "/open/v2/widget/change",
    method: "PUT",
    data: {
      ids: params.ids,
    },
  });

export const apiGetWidgetChangedDiff = (params: { id: number }) =>
  request({
    url: "/open/v2/widget/change",
    method: "GET",
    params: {
      id: params.id,
    },
  });

export const apiGetDownloadUrl = (params: { page_id: number; release_id: number }) =>
  request({
    url: "/open/v2/release/attachments/download/object",
    method: "GET",
    params: {
      page_id: params.page_id.toString(),
      release_id: params.release_id,
    },
  });

export const apiDownloadResource = (params: { url: string }) =>
  request({
    url: params.url,
    method: "GET",
    responseType: "blob",
  });

export const apiGetReleases = (params: { page: number; row: number; payload?: boolean }) =>
  request({
    url: "/open/v2/releases",
    method: "GET",
    params: {
      page: params.page,
      row: params.row,
      payload: params.payload,
    },
  });

export const apiDeleteRelease = (params: { id: number }) =>
  request({
    url: "/open/v2/release",
    method: "DELETE",
    data: {
      id: params.id,
    },
  });

export const apiCreateShare = (params: { id: number }) =>
  request({
    url: "/open/v2/widget/visitor",
    method: "POST",
    data: {
      widget_id: params.id,
    },
  });

export const apiDeleteShare = (params: { id: number }) =>
  request({
    url: "/open/v2/widget/visitor",
    method: "DELETE",
    data: {
      widget_id: params.id,
    },
  });

export const apiUpdateShare = (params: { id: number }) =>
  request({
    url: "/open/v2/widget/visitor",
    method: "PUT",
    data: {
      widget_id: params.id,
    },
  });
