import { NavLink, useLocation } from "react-router-dom";
import arrowRightBoldIcon from "../assets/images/arrow-right-bold.svg";
import overviewIcon from "../assets/images/page/overview.svg";
import accountIcon from "../assets/images/page/account.svg";
import toolsIcon from "../assets/images/page/tools.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Disclosure } from "@headlessui/react";
import clsx from "clsx";

interface PageCfg {
  label: string;
  href: string;
  icon?: string;
}
interface Props {
  className?: string;
  onFinish?: () => void;
}
export const AppSidebar = ({ className, onFinish = () => undefined }: Props) => {
  const [hovering, setHovering] = useState(false);
  const ref1 = useRef<HTMLImageElement | null>(null);
  const ref2 = useRef<HTMLImageElement | null>(null);
  const navTree = [
    {
      label: "Overview",
      href: "/overview",
      icon: overviewIcon,
    },
    {
      label: "Account Settings",
      icon: accountIcon,
      menu: [
        {
          label: "Profile",
          href: "/profile",
        },
        {
          label: "Security",
          href: "/setting",
        },
        {
          label: "Personalization",
          href: "/preference",
        },
      ],
    },
    {
      label: "Tools & Services",
      icon: toolsIcon,
      menu: [
        {
          label: "API Key",
          href: "/api_service",
        },
        {
          label: "Widget",
          href: "/widgets",
        },
        {
          label: "Payment",
          href: "/api_service/billing_history",
        },
      ],
    },
  ];

  useEffect(() => {
    if (ref1.current && ref2.current) {
      if (hovering) {
        ref1.current.style.transform = "translateY(0)";
        ref2.current.style.transform = "translateY(0)";
      } else {
        ref1.current.style.transform = "translateY(-100%)";
        ref2.current.style.transform = "translateY(-100%)";
      }
    }
  }, [hovering]);

  return (
    <div className={clsx("w-[15rem] py-5 flex flex-col items-center bg-white", className)}>
      <div className="px-2.5 flex flex-col w-full gap-[10px]">
        {/* {pagesCfg.map((pageCfg, index) => (
          <Item key={index} pageCfg={pageCfg} />
        ))} */}
        {_.map(navTree, (nav) => {
          if (nav.menu) {
            return (
              <Disclosure key={`${nav.href}${nav.label}`} defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full py-2 pr-2 justify-between focus:outline-none items-center">
                      <div className="flex items-center">
                        {!!nav.icon && <img className="mr-2.5" alt="..." src={nav.icon} />}
                        <span className="text-bold md:text-xs">{nav.label}</span>
                      </div>
                      <img
                        alt="arrow"
                        src={arrowRightBoldIcon}
                        className={`${open ? "rotate-90 transform" : ""} h-3 w-3`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="ml-[7px] pl-3.5 text-sm text-gray-500 border-l">
                      {_.map(nav.menu, (link) => {
                        return (
                          <Item
                            onFinish={onFinish}
                            type="submenu"
                            key={link.href}
                            pageCfg={link}
                            className="flex items-center"
                          />
                        );
                      })}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          }
          if (nav.href) {
            return <Item onFinish={onFinish} textCls="!font-bold md:!text-xs" key={nav.href} pageCfg={nav} />;
          }
          return null;
        })}
      </div>
    </div>
  );
};

const Item = ({
  pageCfg,
  className,
  textCls,
  type,
  onFinish,
}: {
  pageCfg: PageCfg;
  className?: string;
  textCls?: string;
  type?: string;
  onFinish: () => void;
}) => {
  const { pathname } = useLocation();
  const isSubmenu = type === "submenu";
  const match = useMemo(() => `${pathname}` === pageCfg.href, [pathname, pageCfg.href]);

  return (
    <NavLink
      to={pageCfg.href}
      onClick={() => {
        onFinish();
      }}
      className={clsx(
        { "bg-b4": match && isSubmenu },
        { "px-2.5": isSubmenu },
        "flex cursor-pointer py-2 items-center transition-colors hover:bg-b4",
        className
      )}
    >
      {!!pageCfg.icon && <img className="mr-2.5" alt="..." src={pageCfg.icon} />}
      <span className={clsx({ "font-bold": match && isSubmenu }, "text-normal md:text-xs capitalize", textCls)}>
        {pageCfg.label}
      </span>
    </NavLink>
  );
};
