import { forwardRef, DetailedHTMLProps, InputHTMLAttributes, useState } from "react";
import searchIcon from "src/assets/images/icon_search.svg";

export interface AppInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

export const AppInput = forwardRef<HTMLInputElement, AppInputProps>(({ type, ...rest }, ref) => {
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);

  const style = {
    border: "1px solid #D8D8D8",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
    background: "#FFFFFF",
  };

  const hoverStyle = {
    background: "linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(to right, #3A30DD, #FE3876) border-box",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
    border: "1px solid transparent",
  };

  const inputElement = (
    <input
      {...rest}
      ref={ref}
      type={type}
      style={focus || hover ? hoverStyle : style}
      className="appearance-none text-normal text-sm border border-b3 rounded px-[0.625rem] h-9 flex items-center focus-visible:outline-none transition-colors duration-300"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    />
  );

  if (type === "search") {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          alert("search");
        }}
        className="relative"
      >
        {inputElement}
        <button
          type={"submit"}
          className="absolute top-0 bottom-0 my-auto h-7 w-7 right-1 rounded-full flex items-center justify-center hover:bg-gray-800/10"
        >
          <img alt="..." src={searchIcon} />
        </button>
      </form>
    );
  }

  return inputElement;
});
